<template>
  <div class="bj" :style="{ width: width + 'px', height: height + 'px' }">
    <div class="header" v-if="datas.hotel">
      <div class="header_font">
        <img src="../assets/singran.png" alt="" class="logo" />
        芯任卫生联防联控云平台{{ num }}
      </div>
      <div style="width: 50%">
        <img src="../assets/hotel_logo.png" alt="" class="hotel_logo" />
        {{ datas.hotel.name }}
      </div>
      <div style="width: 25%" class="header_right">
        {{ date }}
      </div>
    </div>
    <div class="center">
      <dv-border-box-1>
        <van-row style="height: 100%">
          <van-col span="6" class="padding_left">
            <dv-border-box-3>
              <div class="center_padding">
                <div style="height: 100%">
                  <div class="center_title">洗涤工厂实时监控</div>
                  <div class="center_title_height">
                    <div class="factory">
                      数据源:绿竹风智慧洗涤工厂
                      <span
                        :style="{
                          color: datas.status == '休息中' ? 'red' : 'green',
                        }"
                        >[{{ datas.status }}]</span
                      >
                    </div>
                    <div v-for="e in datas.cctv" :key="e">
                      <lazy-component>
                        <img v-lazy="e.snapshot" :key="e" style="width: 55%" />
                      </lazy-component>
                      <div class="datas_font">
                        {{ e.stage_names[0] }}
                      </div>
                    </div>
                  </div>
                  <div class="center_title">客房清洁动态监控</div>
                  <div class="center_title_font" v-if="datas.hotel">
                    数据源:{{ datas.hotel.name }}
                  </div>
                  <div class="center_title_swipe">
                    <van-swipe
                      class="my-swipe"
                      :autoplay="3000"
                      indicator-color="white"
                    >
                      <van-swipe-item v-for="e in datas.room_info" :key="e">
                        <div class="center_title_flex">
                          <img
                            :src="
                              'http://api.singran.vip/staffavatar/' +
                              e.staff_code
                            "
                            alt=""
                            class="center_title_border"
                          />
                          <div class="center_title_padding">
                            <div class="name_font">
                              姓名：{{ e.staff_info.name }}
                            </div>
                            <div class="room_number_font">
                              打扫房间：{{ e.room_number }}
                            </div>
                            <div class="name_font">
                              打扫时间：{{ e.minute }}
                            </div>
                          </div>
                        </div>
                      </van-swipe-item>
                    </van-swipe>
                  </div>
                </div>
              </div>
            </dv-border-box-3>
          </van-col>
          <van-col span="18" class="padding_right">
            <div class="right_padding">
              <div class="right_flex">
                <div class="right_header">
                  <dv-border-box-3>
                    <div class="right_header_padding">
                      <div class="right_header_title">
                        <dv-decoration-1 class="decoration_width" />
                        <div class="right_title">累计提供放心房间次数</div>
                        <dv-decoration-1 class="decoration_width" />
                      </div>
                      <div class="right_header_center">
                        <div class="number" v-if="datas.room_count">
                          {{ datas.room_count[0] || 0 }}
                        </div>
                        <div class="number" v-if="datas.room_count">
                          {{ datas.room_count[1] || 0 }}
                        </div>
                        <div class="number" v-if="datas.room_count">
                          {{ datas.room_count[2] || 0 }}
                        </div>
                        <div class="number" v-if="datas.room_count">
                          {{ datas.room_count[3] || 0 }}
                        </div>
                        <div class="number" v-if="datas.room_count">
                          {{ datas.room_count[4] || 0 }}
                        </div>
                        <div class="number" v-if="datas.room_count">
                          {{ datas.room_count[5] || 0 }}
                        </div>
                      </div>
                      <div class="right_header_footer">
                        <div class="footer_font">超凡</div>
                        <div class="footer_padding">卫生质量</div>
                        <van-rate v-model="value" color="#08E5FF" />
                        <div class="footer_paddings">
                          <div class="footer_padding">
                            数据来自洗涤工厂实时在线监控及酒店
                          </div>
                          <div>
                            客房卫生清洁追溯，所有数据已通过区块链
                            <div class="footer_padding">
                              完成存证，不可伪造，不可篡改。
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="right_header_footers">
                        <img
                          src="../assets/xinranvip.png"
                          alt=""
                          class="logos"
                        />
                        <!-- <div class="logos_text">我用芯 您放心</div> -->
                      </div>
                    </div>
                  </dv-border-box-3>
                  <div class="right_header_flex">
                    <dv-border-box-3 class="right_header_flex_top">
                      <div class="right_header_paddingss">
                        <van-swipe
                          :autoplay="3000"
                          indicator-color="white"
                          class="hotel_box"
                        >
                          <van-swipe-item
                            v-for="e in datas.hotel_picture"
                            :key="e"
                          >
                            <img :src="e" alt="" class="hotel_img" />
                          </van-swipe-item>
                          <template #indicator></template>
                        </van-swipe>
                        <view class="hotel_text">全国 No.000001 放芯酒店</view>
                      </div>
                    </dv-border-box-3>
                    <dv-border-box-4
                      class="right_header_flex_bottom"
                      :reverse="true"
                      :color="['#2862B7', '#2862B7']"
                    >
                      <div class="right_header_paddings">
                        <div class="right_title" style="text-align: center">
                          洗涤追溯
                        </div>
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: space-around;
                          "
                        >
                          <div>
                            <div class="bucao_count">
                              {{ datas.bucao_count || 0 }}
                            </div>
                            <div class="zhuisu">累计追溯</div>
                          </div>
                          <dv-capsule-chart
                            :config="bucaos_config"
                            class="bucaos_config"
                          />
                        </div>
                      </div>
                    </dv-border-box-4>
                  </div>
                </div>
                <div class="right_footer">
                  <dv-border-box-4 :color="['#2862B7', '#2862B7']">
                    <div class="right_footer_padding">
                      <div class="right_footer_flex">
                        <div class="right_footer_title">清洁剂品牌</div>
                        <dv-active-ring-chart
                          :config="detergents_config"
                          class="detergents_config"
                        />
                        <div class="margin-top">
                          <img
                            src="../assets/xidilogo.png"
                            alt=""
                            class="xidilogo"
                          />
                          泰华施
                        </div>
                      </div>
                      <div class="right_footer_flex">
                        <div class="right_footer_title">洗涤温度</div>
                        <dv-percent-pond
                          :config="temp_config"
                          class="temp_config"
                        />
                        <div class="temp_text">
                          布草洗涤温度达到<span class="temp_text_big"
                            >60-70℃</span
                          >， 可更好的去污、杀菌、漂白的作用。
                        </div>
                      </div>
                      <div class="right_footer_flex">
                        <div class="right_footer_title">中和pH值</div>
                        <dv-percent-pond
                          :config="ph_config"
                          class="ph_config"
                        />
                        <div class="temp_text">
                          pH值在
                          <span class="temp_text_big">4.0—8.5</span>
                          ，越接近中性<span class="temp_text_big">7</span
                          >，洗涤后使用布草体感越舒适。
                        </div>
                      </div>
                      <div class="right_footer_flex">
                        <div class="right_footer_title">水硬度</div>
                        <dv-water-level-pond
                          :config="hd_config"
                          class="hd_config"
                        />
                        <div class="temp_texts">
                          不超过<span class="temp_text_big">25mg/L</span
                          >，越趋近<span class="temp_text_big">0</span
                          >越有利于布草高质量护理。
                        </div>
                      </div>
                    </div>
                  </dv-border-box-4>
                </div>
              </div>
            </div>
          </van-col>
        </van-row>
      </dv-border-box-1>
    </div>
  </div>
</template>
<script>
import { Col, Row, Swipe, SwipeItem, Rate, Notify, Dialog } from "vant";
import { ref } from "@vue/reactivity";
import store from "../store/index";
import api from "../utils/api";
export default {
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Rate.name]: Rate,
    [Notify.name]: Notify,
    [Dialog.name]: Dialog,
  },
  setup() {
    const value = ref(5);
    const num = store.state.edition;
    return {
      num,
      value,
      date: ref(),
      bucaos_config: ref({}),
      temp_config: ref({
        value: 66,
        borderWidth: 3,
        borderRadius: 10,
        borderGap: 5,
        formatter: "{value}℃",
        colors: ["#F6AFB1", "red"],
      }),
      hd_config: ref({
        data: [26],
        waveHeight: 10,
        shape: "roundRect",
        formatter: "{value}mg/L",
      }),
      ph_config: ref({
        value: 66,
        borderWidth: 3,
        borderRadius: 10,
        borderGap: 5,
        formatter: "{value}pH",
        colors: ["#99CC82", "green"],
      }),
      datas: ref({}),
      Qrcode: ref({}),
      detergents_config: ref({
        data: [],
        lineWidth: 5,
        digitalFlopStyle: {
          fontSize: 14,
        },
      }),
      height: ref(),
      width: ref(),
      imgLoad: ref(false),
      network_time: 5000,
      network_Id: null,
    };
  },
  mounted() {
    this.height = document.documentElement.clientHeight;
    this.width = document.documentElement.clientWidth;
    this.Qrcode = encodeURIComponent(
      JSON.stringify({
        scene: this.$route.query.hotel_code,
        page: `pages/hotel/x`,
        is_hyaline: true,
        auto_color: true,
      })
    );
    this.network();
    this.network_refreh();
    this.hotellargescreen();
    this.timers = setInterval(() => {
      this.datas.room_info = [];
      this.hotellargescreen();
    }, 1800000);
    this.timer = setInterval(() => {
      this.date = this.getTime(); // 修改数据date
    }, 1000);
  },
  created() {
    // window.Android.playTone("labby");
  },
  beforeUnmount() {
    if (this.timer) {
      clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
    }
    if (this.timers) {
      clearInterval(this.timers); // 在Vue实例销毁前，清除我们的定时器
    }
  },
  methods: {
    _isMobile(e) {
      this.flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      if (this.flag == null) {
        console.log("==>PC端");
      } else {
        console.log("==>移动端");
        window.Android.playTone(e);
      }
    },
    imgload() {
      this.imgLoad = true;
    },
    getTime() {
      let time = new Date();
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let dates = time.getDate();
      let h = time.getHours();
      h = h < 10 ? "0" + h : h;
      let m = time.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = time.getSeconds();
      s = s < 10 ? "0" + s : s;
      return year + "年" + month + "月" + dates + "日" + h + ":" + m + ":" + s;
    },
    hotellargescreen() {
      api
        .hotellargescreen({
          hotel_code: this.$route.query.hotel_code,
          device_code: this.$route.query.device_code,
        })
        .then((res) => {
          console.log("数据", res);
          if (res.data.data.bucao_summary) {
            res.data.data.bucao_summary.map((e) => {
              e.value = e.count;
            });
            this.bucaos_config.data = res.data.data.bucao_summary.slice(0, 6);
            this.bucaos_config = { ...this.bucaos_config };
          }
          if (res.data.data.room_count) {
            let room_count = String(res.data.data.room_count).split("");
            if (room_count.length < 6) {
              const num = 6 - room_count.length;
              for (let i = 1; i <= num; i++) {
                room_count.unshift("0");
              }
            }
            res.data.data.room_count = room_count;
          }
          if (res.data.data.temp) {
            res.data.data.temp = (res.data.data.temp / 10).toFixed(2);
            this.temp_config.value = res.data.data.temp;
            this.temp_config = { ...this.temp_config };
          }
          if (res.data.data.ph) {
            res.data.data.ph = res.data.data.ph.toFixed(2);
            this.ph_config.value = (res.data.data.ph / 15).toFixed(2) * 100;
            this.ph_config.formatter = res.data.data.ph + "pH";
            this.ph_config = { ...this.ph_config };
          }
          if (res.data.data.hd) {
            res.data.data.hd = res.data.data.hd.toFixed(2);
            this.hd_config.data[0] = res.data.data.hd;
            this.hd_config = { ...this.hd_config };
          }
          if (res.data.data.cctv) {
            res.data.data.cctv.map((e) => {
              e.cctv_id = encodeURIComponent(JSON.stringify(e.cctv_id));
            });
          }
          if (res.data.data.detergents_summary) {
            res.data.data.detergents_summary.map((e) => {
              e.value = e.val;
            });
            this.detergents_config.data = res.data.data.detergents_summary;
            this.detergents_config = { ...this.detergents_config };
          }
          if (res.data.data.room_info) {
            res.data.data.room_info.map((e) => {
              e.minute = e.minute.split(" ")[1];
            });
          }
          if (res.data.data.bucao_count && res.data.data.bucao_count > 10000) {
            res.data.data.bucao_count =
              (res.data.data.bucao_count / 10000).toFixed(1) + "万件";
            res.data.data.bucao_summary.map((e) => {
              e.value = (e.value / 10000).toFixed(1);
            });
          }
          this.datas = res.data.data;
        });
      // .catch((err) => {
      //   console.log(err);
      //   Notify(err);
      // });
    },
    network() {
      if (window.navigator.onLine == true) {
        let img = new Image();
        img.src =
          "http://fdfs.singran.vip/group1/M00/39/36/rBEAA2DAhiaAcZK4AADaqwfWS6s275.png";
        img.onload = () => {
          console.log("网络已连接");
        };
        img.onerror = () => {
          console.log("无网络连接，请检查网络");
          Dialog.alert({
            title: "网络已断开连接",
            message: "请检查网络",
          }).then(() => {});
          this._isMobile("cut_off_internet");
        };
      } else {
        console.log("网络未连接--请检查网络");
      }
    },
    network_refreh() {
      if (this.network_Id) {
        clearInterval(this.network_Id);
      } else {
        console.log(`打开定时器${this.network_time}`);
        this.network_Id = setInterval(() => {
          this.network();
        }, this.network_time);
      }
    },
  },
};
</script>
<style lang='less' scoped>
/deep/.dv-capsule-chart .unit-text {
  font-size: 6px;
}
/deep/.dv-capsule-chart .capsule-item {
  height: 0;
}
/deep/.dv-percent-pond text {
  font-size: 14px;
  font-weight: 400;
}
/deep/.dv-water-pond-level text {
  font-size: 14px;
  font-weight: 400;
}
/deep/.dv-water-pond-level canvas {
  margin-left: 0;
  margin-right: 0.08889rem;
}
/deep/.dv-capsule-chart .capsule-item .capsule-item-column {
  height: 0.03889rem;
}
/deep/.dv-capsule-chart .capsule-container {
  justify-content: space-around;
}
/deep/.dv-capsule-chart .label-column div {
  margin-top: 6px;
}
/deep/.dv-capsule-chart .label-column {
  display: flex;
  flex-direction: column;
  width: 20px;
  justify-content: space-between;
  box-sizing: border-box;
  padding-right: 0.0111rem;
  text-align: right;
  font-size: 5px;
  margin-top: 9px;
}
/deep/.dv-capsule-chart .label-column div {
  height: 8px;
  line-height: 0.16222rem;
}
/deep/.dv-capsule-chart .capsule-item {
  margin: 7px 0;
}
/deep/.dv-capsule-chart {
  height: 0.22222rem;
  font-size: 0.13333rem;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
/deep/.unit-label {
  display: none;
}
/deep/.dv-capsule-chart {
  padding: 0px;
}
.bj {
  background-image: url("../assets/bg1.png");
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
}
.header {
  height: 8%;
  width: 100%;
  font-size: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.center {
  height: 92%;
  width: 100%;
}
.padding_left {
  display: flex;
  padding: 10px 2px 10px 10px;
}
.padding_right {
  display: flex;
  padding: 10px 10px 10px 2px;
}
.center_padding {
  padding: 18px 12px;
  height: 70%;
}
.right_padding {
  height: 100%;
  width: 100%;
}
.center_title {
  font-size: 14px;
  color: #fff;
}
.right_flex {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.right_header {
  height: 65%;
  display: flex;
}
.right_header_flex {
  width: 45%;
  display: flex;
  flex-direction: column;
}
.right_footer {
  height: 34%;
}
.right_header_padding {
  padding: 18px 12px 10px 12px;
}
.right_header_paddings {
  padding: 16px 12px;
}
.right_header_paddingss {
  padding: 16px 14px 10px 14px;
}
.right_header_title {
  display: flex;
  justify-content: space-between;
  padding: 5px 0 10px 0;
}
.right_title {
  font-size: 14px;
  color: #fff;
  width: 100%;
}
.right_header_center {
  padding-top: 3px;
  display: flex;
  justify-content: center;
}
.number {
  font-size: 20px;
  font-weight: 600;
  color: #08e5ff;
  width: 35px;
  height: 35px;
  background: rgba(2, 33, 95, 0.6);
  text-align: center;
  line-height: 35px;
  margin: 0 5px;
}
.right_header_footer {
  width: 140px;
  // padding-top: 3px;
  padding-top: 10px;
  font-size: 8px;
  color: #fff;
  margin: auto;
}
.right_footer_padding {
  padding: 15px 12px 5px 12px;
  display: flex;
  justify-content: space-around;
}
.right_footer_flex {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  width: 120px;
}
.right_footer_title {
  padding: 5px 0;
  font-size: 10px;
  color: #fff;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 16px;
  text-align: center;
  // background: chartreuse;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.qrcode {
  width: 50px;
}
.qrcodes {
  width: 70px;
  // height: 50px;
}
.center_title_height {
  height: 70%;
  color: #fff;
}
.center_title_swipe {
  height: 30%;
  color: #fff;
}
.center_title_flex {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.center_title_border {
  width: 40px;
  height: 40px;
  border-radius: 5px;
}
.center_title_padding {
  height: 100px;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  padding-left: 20px;
  justify-content: center;
}
.bucaos_config {
  width: 120px;
  height: 100px;
}
.temp_config {
  width: 120px;
  height: 35px;
}
.temp_text {
  color: #fff;
  width: 120px;
  font-size: 8px;
  padding-top: 5px;
  text-align: left;
}
.temp_texts {
  color: #fff;
  width: 120px;
  font-size: 8px;
  text-align: left;
}
.hd_config {
  width: 120px;
  height: 40px;
}
.ph_config {
  width: 120px;
  height: 35px;
}
.detergents_config {
  width: 120px;
  height: 65px;
  margin-top: -15px;
}
.margin-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(187, 255, 0);
  margin-top: -10px;
  font-size: 10px;
}
.xidilogo {
  width: 40px;
}
.header_font {
  font-size: 9px;
  width: 25%;
  text-align: left;
  padding-left: 15px;
}
.logo {
  width: 17.5px;
  padding-right: 5px;
}
.hotel_logo {
  width: 50px;
  padding-right: 5px;
}
.factory {
  padding: 2.5px 0;
  font-size: 7px;
}
.datas_font {
  font-size: 10px;
  padding: 4px;
}
.center_title_font {
  padding: 2px 0;
  font-size: 7px;
  color: #fff;
}
.name_font {
  font-size: 7px;
  text-align: left;
}
.room_number_font {
  font-size: 7px;
  text-align: left;
  padding: 2.5px 0;
}
.decoration_width {
  width: 20%;
  height: 25px;
}
.footer_font {
  font-size: 25px;
  color: #08e5ff;
  font-weight: 600;
}
.footer_padding {
  padding: 2.5px 0;
}
.footer_paddings {
  text-align: center;
  font-size: 6px;
}
.bucao_count {
  font-size: 12px;
  color: #08e5ff;
}
.zhuisu {
  font-size: 5px;
  color: #fff;
}
.right_header_flex_top {
  height: 45%;
}
.right_header_flex_bottom {
  height: 55%;
}
.header_right {
  font-size: 9px;
}
.right_header_footers {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
}
.logos {
  // width: 40px;
  height: 25px;
}
.logos_text {
  padding-left: 5px;
  font-size: 20px;
  color: #ffc000;
  font-family: STKaiti;
}
.number_text {
  color: #fff;
  font-size: 8px;
}
.temp_text_big {
  font-size: 10px;
  color: rgb(187, 255, 0);
}
.hotel {
  width: 200px;
}
.hotel_box {
  width: 175px;
  height: 80px;
}
.hotel_img {
  width: 100%;
  height: 100%;
}
.hotel_text {
  z-index: 9;
  position: absolute;
  font-size: 12px;
  color: #fff;
  left: 12px;
  background: rgba(0, 0, 0, 0.3);
  width: 175px;
  bottom: 12px;
  padding: 2px 0;
}
</style>


