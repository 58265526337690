<template>
  <Lobby />
</template>

<script>
import Lobby from "../../../components/hotel_lobby.vue";

export default {
  name: "hotel_hfrd",
  components: {
    Lobby,
  },
};
</script>